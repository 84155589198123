import $ from 'jquery';

var MenuButton = function () {
  var menuOpenButton = $('.menu-button-open');
  var menuCloseButton = $('.menu-button-close');
  var admineMenu = $('.admin-menu');
  var overlay = $('#js-admin-menu-overlay');
  var body = $('body');

  menuOpenButton.on('click', function () {
    body.addClass('menu-open');
    if (!admineMenu.hasClass('is-open')) {
      admineMenu.addClass('is-open');
    }
    overlay.on('click', function() {
      body.removeClass('menu-open');
      admineMenu.removeClass('is-open');
    })
  });

  menuCloseButton.on('click', function() {
    body.removeClass('menu-open');
    admineMenu.removeClass('is-open');
  });
};

$(document).on('turbolinks:load', MenuButton);
